<template>
  <div class="nav-menu-lang-switcher --light" ref="switcher">
    <button
      class="button-round-1 --dark nav-menu-lang-switcher__button"
      @click="toggleDropdown"
    >
      <span>{{ $t(`navigation.market`) }}: {{ current.name }}</span>
      <img
        src="~/assets/img/ico/ico-arrow-down-light.svg"
        alt="Ico arrow down light"
        width="12"
        height="13"
        data-animate="lang-switcher-ico"
      />
    </button>
    <div
      class="nav-menu-lang-switcher__dropdown"
      data-animate="lang-switcher-dropdown"
    >
      <ul class="nav-menu-lang-switcher__list">
        <li v-for="market in locales" :key="market.code">
          <a
            class="nav-menu-lang-switcher__link"
            :class="{
              '--active': locale === market.code,
            }"
            :href="`${localePath('index', market.code)}`"
          >
            <span data-animate="lang-switcher-link">{{ market.name }}</span></a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationMenuLangSwitcher',
}
</script>

<script setup>
import { gsap } from 'gsap'

const route = useRoute()
const { locale, locales } = useI18n()

const switcher = ref(null)
const active = ref(false)

const tl = ref(null)

const current = computed(() => {
  return locales.value.find(({ code }) => code === locale.value)
})

onMounted(() => {
  tl.value = animate()
})

watch(
  () => active.value,
  (active) => {
    active ? tl.value.duration(0.4).play() : tl.value.reverse()
  }
)

watch(
  () => route.href,
  () => {
    if (active.value) {
      toggleDropdown()
    }
  }
)

const toggleDropdown = () => {
  active.value = !active.value
}

// Animazioni
const animate = (params) => {
  const parent = switcher.value
  const dropdown = parent.querySelectorAll(
    '[data-animate="lang-switcher-dropdown"]'
  )
  const ico = parent.querySelectorAll('[data-animate="lang-switcher-ico"]')
  const links = parent.querySelectorAll('[data-animate="lang-switcher-link"]')

  const tl = gsap.timeline({
    paused: true,
    ...params,
  })

  tl.set(dropdown, {
    autoAlpha: 1,
  })
  tl.to(
    dropdown,
    {
      height: 'auto',
      ease: 'power1.out',
    },
    'start'
  )
  tl.to(
    ico,
    {
      rotation: 180,
    },
    'start'
  )
  tl.to(
    links,
    {
      y: '0%',
      stagger: 0.05,
    },
    'start'
  )

  return tl
}
</script>

<style lang="scss">
@import './style.scss';
</style>
